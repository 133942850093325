import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Clinic from '../components/Clinic'

const ClinicPage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Our Clinics — {site.siteMetadata.title}</title>
        <meta name="description" content={"Our Clinics " + site.siteMetadata.description} />
      </Helmet>
      <div className="-clinics">
        <h1 className="post-title">Clinic locations</h1>
        <div className="clinics">
          {site.siteMetadata.clinics.map( 
            c => <Clinic 
              data={c} 
              key={c.key} 
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
export default ClinicPage
export const pageQuery = graphql`
  query ClinicPageQuery{
    site {
      siteMetadata {
        title
        description
        clinics {
          key
          id
          title
          address
          telephone
          fax
          email
          map {
            url
            center {
              lat
              lng
            }
          }
        }
      }
    }
  }
`