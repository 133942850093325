import React from "react"
import styles from './Clinic.module.scss'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { FaFax, FaPhone, FaMapPin, FaTelegramPlane, FaExternalLinkAlt } from 'react-icons/fa'

const Clinic = (props) => {

  const { isLoaded } = useJsApiLoader({
    id: 'gmap',
    googleMapsApiKey: 'AIzaSyA8hLAUrcQf1BdyHHcMVxw1apzOniXDrlk'
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  return (
    <div className={`card ${styles.Clinic}`} id={props.data.id}>
      <div className={styles.header}>
        { isLoaded && <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={props.data.map.center}
          zoom={16}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Marker
            position={props.data.map.center}/>
        </GoogleMap> }
      </div>
      <div className={styles.info}>
      <h1>{props.data.title}</h1>
        <div
          className={styles.infoLine}>
            <FaMapPin /> <a href={props.data.map.url} target="_blank" rel="noopener noreferrer">{props.data.address} <FaExternalLinkAlt size='0.8em' /></a> 
        </div>
        <h2>Contact</h2>
        <div
          className={styles.infoLine}>
            <FaPhone /> <a href={`tel:${props.data.telephone}`}>{props.data.telephone}</a>
        </div>
        {props.data.fax && 
          <div
            className={styles.infoLine}>
              <FaFax /> {props.data.fax}
          </div>
        }
        <div
          className={styles.infoLine}>
          <FaTelegramPlane /> <a href={`mailto:${props.data.email}`}>{props.data.email}</a>
        </div>
      </div>
    </div>
  )}
export default Clinic
